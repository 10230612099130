<template>
  <div></div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang='less'>
div {
  min-height: 100vh;
  background: #fff;
}
</style>
